<template>
  <div
    v-if="isLoading"
    class="min-vh-100 bg-waves d-flex align-items-center py-1"
  >
    <div class="w-100 margin-top-min-navbar">
      <loader></loader>
    </div>
  </div>
  <div v-else>
    <div class="min-vh-100-lg d-flex" v-if="track">
      <div class="container w-100">
        <div class="row">
          <div class="col-lg-9">
            <!-- this representa a target box for the global player to "appear" to be placed in. We do this to ensure the player continues to be instantiated when navigating to other routes -->
            <div>
              <div class="mb-5">
                <div class="w-100">
                  <div class="mb-4">
                    <router-view></router-view>
                  </div>
                  <div class="text-start">
                    <div class="d-lg-flex">
                      <div class="flex-grow-1">
                        <h1 class="color-brand">
                          <div class="text-uppercase display-7 text-break pe-2">
                            {{ track.songTitle }}
                          </div>
                        </h1>
                      </div>
                      <div class="flex-1">
                        <action-buttons :track="track"></action-buttons>
                      </div>
                    </div>
                    <p class="mb-4">
                      {{ track.description || 'No description' }}
                    </p>
                    <user-info
                      class="mb-4"
                      :userKey="track.ownerCognitoIdentityId || track.ownerId"
                    >
                    </user-info>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-4 z-index-999 position-relative">
            <aside class="position-sticky top-navbar text-center">
              <slot name="sidebar"></slot>
              <div>
                <user-track-list
                  :userKey="track.ownerCognitoIdentityId || track.ownerId"
                  :compact="true"
                ></user-track-list>
                <router-link
                  :to="{
                    name: 'userIndex',
                    params: {
                      userKey: track.ownerCognitoIdentityId || track.ownerId,
                    },
                  }"
                  ><small>See all works by this user</small></router-link
                >
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSeoMeta } from '@vueuse/head';
import getTrack from '../../services/getTrack';
import Loader from '../Loader.vue';
import UserInfo from '../UserInfo.vue';
import UserTrackList from '../UserTrackList.vue';
import ActionButtons from './ActionButtons.vue';

export default {
  name: 'ViewTrackIndexPublic',
  components: {
    Loader,
    UserInfo,
    UserTrackList,
    ActionButtons,
  },
  props: {
    track_id: {
      type: String,
      required: true,
    },
    authToken: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      track: undefined,
      isLoading: false,
    };
  },
  async beforeMount() {
    try {
      this.isLoading = true;

      this.track = await getTrack(this.track_id, {
        authToken: this.authToken,
      });

      useSeoMeta({
        title: this.track.songTitle,
        ogTitle: this.track.songTitle,
        description: this.track.description,
        ogDescription: this.track.description,
        meta: [{ name: 'description', content: this.track.description }],
      });
    } catch (err) {
      console.error(err);

      this.$notify(
        {
          title: 'Error',
          text:
            err.response?.status === 404 || err.response?.status === 403
              ? 'This track is no longer available'
              : 'We encountered a problem when loading the track',
          type: 'info',
          group: 'default',
        },
        4000,
      );
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped></style>
