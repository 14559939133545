<template>
  <div class="min-vh-100 bg-waves d-flex align-items-center py-1 text-center">
    <div class="container w-100 margin-top-min-navbar">
      <auth>
        <loader v-if="download() && isDownloading"></loader>
        <div v-else class="container w-100">You can close this window</div>
      </auth>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import Loader from './Loader.vue';
import { record } from '../services/analytics';
import Mix from '../libs/createMix';
import { DOWNLOAD_MIX, DOWNLOAD_MIX_FAIL } from '../consts/analytics';
import Auth from './Auth.vue';

export default {
  name: 'DownloadMix',
  components: {
    Loader,
    Auth,
  },
  data() {
    return {
      track: undefined,
      isDownloading: false,
      hasDownloaded: false,
    };
  },

  mounted() {
    // if we login using oauth the tab will redirect and then back. We store the url so that we can continue with the download after redirect
    // ideally we'd send the customState along with the redirect url, but that does not seems to be supported with the way we use amplify-ui auth
    window.sessionStorage.setItem(
      'oauth_post_login_redirect',
      window.location.href,
    );
  },

  methods: {
    async download() {
      if (this.hasDownloaded || this.isDownloading) return false;

      const { track_id, isAdmin } = this.$route.query;

      const body = {
        ...JSON.parse(atob(this.$route.query.state)),
      };

      try {
        this.isDownloading = true;

        record(DOWNLOAD_MIX, body);

        const endpoint = isAdmin ? 'admin' : 'cdn';
        const path = isAdmin
          ? `/admin/tracks/${track_id}/download-mix`
          : `/tracks/${track_id}/download-mix`;

        const { _url } = await API.post(endpoint, path, {
          body,
        });

        const client = Mix.create({});

        const { url } = await client.poll(_url);

        location.replace(url);
      } catch (err) {
        record(DOWNLOAD_MIX_FAIL, body);

        this.$notify(
          {
            title: 'Error',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            group: 'default',
          },
          4000,
        );
      } finally {
        this.isDownloading = false;
        this.hasDownloaded = true;
      }
    },
  },
};
</script>

<style scoped></style>
