<template>
  <div
    v-if="isLoading"
    class="min-vh-100 bg-waves d-flex align-items-center py-1"
  >
    <div class="w-100 margin-top-min-navbar">
      <loader></loader>
    </div>
  </div>
  <div v-else>
    <div class="min-vh-100-lg d-flex align-items-center" v-if="track">
      <div class="container w-100 mt--navbar-lg">
        <!-- this representa a target box for the global player to "appear" to be placed in. We do this to ensure the player continues to be instantiated when navigating to other routes -->
        <div class="mb-4">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSeoMeta } from '@vueuse/head';
import adminGetTrack from '../../services/adminGetTrack';
import Loader from '../Loader.vue';
// import getTrackStats from '../../services/getTrackstats';

export default {
  name: 'ViewTrackIndex',
  components: {
    Loader,
  },
  data() {
    return {
      track: undefined,
      isLoading: false,
      trackstats: undefined,
    };
  },
  props: {
    track_id: {
      type: String,
      required: true,
    },
  },
  async beforeMount() {
    const { t } = this.$route.query;

    this.track = undefined;

    try {
      this.isLoading = true;

      this.track = await adminGetTrack(this.track_id, {
        t,
      });

      useSeoMeta({
        title: this.track.songTitle,
        ogTitle: this.track.songTitle,
        description: this.track.description,
        ogDescription: this.track.description,
        meta: [{ name: 'description', content: this.track.description }],
      });
    } catch (err) {
      console.error(err);

      this.$notify(
        {
          title: 'Error',
          text:
            err.response?.status === 404 || err.response?.status === 403
              ? 'This track is no longer available'
              : 'We encountered a problem when loading the track',
          type: 'info',
          group: 'default',
        },
        4000,
      );
    } finally {
      this.isLoading = false;
    }

    // try {
    //   const { data } = await getTrackStats(this.track.track_id);
    //   this.trackstats = data.events;
    // } catch (error) {
    //   // do nothing
    // }
  },
};
</script>

<style scoped>
.z-index-top {
  z-index: 9999;
}
.btn-info {
}
</style>
