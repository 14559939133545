import { API } from 'aws-amplify';

export default async (track_id, { authToken } = {}) => {
  const params = [authToken ? `authToken=${authToken}` : ''].filter((e) => !!e);

  const data = await API.get(
    'cdn',
    `/tracks/${track_id}${params ? `?${params.join('&')}` : ''}`,
  );
  return data;
};
