<template>
  <div>
    <authenticator
      :hide-sign-up="true"
      :social-providers="['google']"
      :login-mechanisms="['email']"
      :sign-up-attributes="['email']"
      :services="{ handleSignUp }"
      :initial-state="initialState"
    >
      <template v-slot:header>
        <div class="alert bg-dark mw-auth m-auto mb-4 text-center" role="alert">
          By using this product, you acknowledge that you agree to our
          <router-link :to="{ name: 'termsAndConditions' }"
            >Terms and Conditions</router-link
          >
        </div>
      </template>
      <template v-slot="{ user }">
        <slot v-if="user" :user="user"></slot>
      </template>
    </authenticator>
  </div>
</template>

<script>
// https://ui.docs.amplify.aws/vue/connected-components/authenticator/configuration#sign-up-attributes
// import { Auth } from 'aws-amplify';
// import { v4 as createUuid } from 'uuid';

export default {
  name: 'Auth',
  props: {
    initialState: {
      type: String,
      default: 'signIn',
    },
  },
  // methods: {
  //   handleSignUp(formData) {
  //     const { username, password, attributes } = formData;

  //     return Auth.signUp({
  //       // generate a uuid for username
  //       username: createUuid(),
  //       password,
  //       attributes: {
  //         ...attributes,
  //         // we use email (which is set as an alias in cognito)
  //         email: username,
  //       },
  //     });
  //   },
  // },
};
</script>

<style scoped>
.mw-auth {
  max-width: 500px;
}
</style>
