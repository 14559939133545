<template>
  <div>
    <track-options-modal
      v-if="showOptions"
      :track="track"
      :isAdmin="isAdmin"
      @close="showOptions = false"
    ></track-options-modal>
    <router-link
      :to="{
        name: 'adminViewTrack',
        params: {
          track_id: track.track_id,
        },
      }"
      class="card text-bg-dark"
      style="height: 15rem"
    >
      <div>
        <div class="card-header d-flex">
          <div class="flex-grow-1 overflow-hidden text-truncate pe-2">
            {{ track.label }}
          </div>
          <div>
            <button
              class="btn btn-sm btn-outline-dark text-light"
              @click.prevent="showOptions = !showOptions"
            >
              <i class="bi bi-three-dots-vertical"></i>
            </button>
          </div>
        </div>
        <!--<div class="card-header">
          <button
            class="btn btn-sm btn-outline-dark my--2 ms--2 me-2"
            @click.prevent="play(track)"
          >
            <i class="bi bi-play-fill"></i>
          </button>
          <button
            class="btn btn-sm btn-outline-dark"
            @click.prevent="showOptions = !showOptions"
          >
            <i class="bi bi-three-dots-vertical"></i>
          </button>
        </div>-->

        <div class="card-body">
          <!--<h5 class="card-title">{{ track.songTitle }}</h5>-->
          <p class="card-text">
            {{ track.description || 'No description' }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import TrackOptionsModal from './TrackOptionsModal.vue';

export default {
  name: 'TrackRow',
  components: { TrackOptionsModal },
  data() {
    return {
      showOptions: false,
    };
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async playInline(trackrow) {
      this.$player.playMix(trackrow, {
        autoplay: true,
        isAdmin: this.isAdmin,
      });
    },

    async play(trackrow) {
      this.$router.push({
        name: 'adminMixTrack',
        params: { track_id: trackrow.track_id },
        query: { t: trackrow.modifiedTime },
      });
    },
  },
  computed: {
    cardWidth() {
      return '25rem';
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
</style>
