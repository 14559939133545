import adminGetTracks from './adminGetTracks';
import Dexie from 'dexie';
import Fuse from 'fuse.js';

const db = new Dexie('admin:tracksv2');

let fuse;

let modified_timeCursor;

db.version(3).stores({
  tracks: '&track_id, modified_time, label, description, isDeleted',
});

// indexedDb + Dexie does not support index on boolean
// https://stackoverflow.com/questions/53060324/using-a-field-with-a-boolean-type-in-dexie-js
const mapRow = (record) => ({
  ...record,
  ...(record.isDeleted ? { isDeleted: 1 } : {}),
});

const getMostRecentRecord = async () =>
  await db.tracks.orderBy('modified_time').last();

const init = async () => {
  const result = await adminGetTracks({
    limit: -1,
  });

  await db.tracks.clear();

  try {
    await db.tracks.bulkAdd(result.map(mapRow));
  } catch (err) {
    console.error(err);
  }
};

const refresh = async () => {
  await updateTimestampCursor();

  // if we dont have a most recent, we need to init the db
  if (!modified_timeCursor) {
    await init();

    // mark the most recent updateTimestamp
    await updateTimestampCursor();
  }

  // do one additional request to check if there have been recent changes
  const result = await adminGetTracks({
    limit: -1,
    modifiedSince: modified_timeCursor,
    force: true, // cache buster
  });

  if (result.length > 0) {
    await db.tracks.bulkPut(result.map(mapRow));
    await updateTimestampCursor();
  }

  await purgeDeleted();

  await rebuildSearchIndex();
};

const truncate = async () => Promise.all([db.tracks.clear()]);

const updateTimestampCursor = async () => {
  const mostRecent = await getMostRecentRecord();
  if (mostRecent) {
    if (!modified_timeCursor || mostRecent.modified_time > modified_timeCursor)
      modified_timeCursor = new Date(mostRecent.modified_time).getTime();
  }
};

const getPage = async ({ page, pageSize }) => {
  const records = await db.tracks
    .orderBy('modified_time')
    .reverse()
    .offset(page * pageSize)
    .limit(pageSize)
    .toArray();

  const total = await getCount();

  const hasMore = page * pageSize + pageSize <= total;

  return { records, hasMore };
};

const getSearchIndex = async () => {
  const docs = (await db.tracks.toArray()).map(
    ({ track_id, label, description, isPublished }) => ({
      track_id,
      label,
      description,
      isPublished: isPublished ? 'published listed public' : '',
    }),
  );

  const fuse = new Fuse(docs, {
    keys: ['label', 'description', 'isPublished'],
    includeScore: true,
    minMatchCharLength: 2,
    threshold: 0.5,
    // distance: 30,
  });

  return fuse;
};

const rebuildSearchIndex = async () => {
  fuse = await getSearchIndex();
};

const search = async ({ search, pageSize }) => {
  fuse = fuse || (await getSearchIndex());

  const results = fuse.search(search, {
    limit: pageSize,
  });

  const records = await db.tracks
    .where('track_id')
    .anyOf(...results.map(({ item }) => item.track_id))
    .toArray();

  return { records, hasMore: false };
};

const purgeDeleted = async () => {
  console.log('purge');
  await db.tracks.where('isDeleted').equals(1).delete();
};

const getCount = async () => db.tracks.count();

export default {
  init,
  refresh,
  getPage,
  search,
  getCount,
  truncate,
};
