<template>
  <div>
    <slot v-if="dismissed || hasRequiredAccountDetails"></slot>
    <div v-else>
      <div class="min-vh-almost-100 d-flex align-items-center" v-if="isLoading">
        <div class="w-100">
          <loader></loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import getCurrentUserProfile from "../services/getCurrentUserProfile";
import Loader from './Loader.vue';

export default {
  name: 'Account',
  components: { Loader },
  data() {
    return {
      hasRequiredAccountDetails: true,
      dismissed: false,
      isLoading: false,
    };
  },
  async beforeMount() {
    try {
      this.isLoading = true;

      // determine whether we need some more info - currently username is required
      // const profile = await getCurrentUserProfile();
      // this.hasRequiredAccountDetails = !!profile?.username;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped></style>
