<template>
  <section>
    <div>
      <section
        class="min-vh-100 d-flex align-items-center bg-mixing bg-cover bg-fixed mt--navbar-lg pt-navbar mb-4"
      >
        <div class="container">
          <div class="w-100">
            <div class="row">
              <div class="col-lg-12 d-flex align-items-center text-center">
                <div class="w-100">
                  <div
                    class="p-4 w-50 m-auto min-vh-60 d-flex align-items-center"
                  >
                    <div class="w-100">
                      <h1 class="display-2 mb-5">
                        Play your stems right in the browser using our Free
                        Online Stem-Player
                      </h1>
                      <!--<p class="mb-4">
                    Enable your audience to enjoy your your work by embedding
                    Stem Players in your own Website and have the audio stream
                    directly to your users using our CDN &amp; infrastructure
                    &mdash; all the hard work is already done for you!
                  </p>-->
                      <p class="">
                        <router-link
                          class="btn btn-primary btn-lg me-1 btn-pulse-lg-hover"
                          :to="{ name: 'uploadStems' }"
                          >Upload and start mixing!</router-link
                        >
                      </p>
                    </div>
                  </div>

                  <div class="card bg-dark-4 mb-5">
                    <div class="card-body px-0">
                      <stem-player-funk-demo></stem-player-funk-demo>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import StemPlayerFunkDemo from './StemPlayerFunkDemo.vue';

export default {
  name: 'Home',
  components: { StemPlayerFunkDemo },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
        this.scrollTo(this.$route.hash.replace('#', ''));
      }, 10);
    }
  },
  methods: {
    scrollTo(ref) {
      this.$refs[ref].scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped></style>
