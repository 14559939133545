import { API } from 'aws-amplify';

export default async (track_id, { t, cache } = {}) => {
  const params = [
    t ? `t=${t}` : null,
    cache === false ? `t=${Date.now()}` : '',
  ].filter((e) => !!e);

  const data = await API.get(
    'admin',
    `/admin/tracks/${track_id}${params ? `?${params.join('&')}` : ''}`,
  );

  return data;
};
