<template>
  <div :hidden="!player.track_id">
    <div v-show="show" class="wrapper position-absolute" ref="stemPlayerPanel">
      <stem-player
        v-if="player.track_id"
        :track_id="player.track_id"
        :autoplay="player.autoplay"
        :isAdmin="player.isAdmin"
        :authToken="player.authToken"
        ref="stemplayer"
        @load="onStemPlayerLoad"
      ></stem-player>
    </div>
  </div>
  <button
    v-if="!show && player.track_id"
    class="position-fixed z-index-999 bottom-0 end-0 btn btn-primary btn-lg rounded-circle border-0 display-4 m-4 animation-pulse"
    @click="view(player)"
  >
    <i class="bi bi-volume-up"></i>
  </button>
</template>

<script>
import StemPlayer from './StemPlayer';

export default {
  name: 'StemPlayerRenderer',
  components: {
    StemPlayer,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      track: undefined,
      showInfo: false,
    };
  },
  beforeMount() {
    this.emitter.on('STEMPLAYER_TARGET_MOUNTED', () => {
      this.show = true;
    });
  },
  mounted() {
    this.show = !!document.getElementById('stem-player-target');
    this.addListeners();
    this.render();
  },
  watch: {
    $route() {
      this.show = false;
    },
    show() {
      this.render();
    },
  },
  methods: {
    render() {
      const player = this.$refs.stemPlayerPanel;
      const target = document.getElementById('stem-player-target');
      if (target && player) {
        this.renderInTarget();
      }
    },
    renderInTarget() {
      requestAnimationFrame(() => {
        const target = document.getElementById('stem-player-target');
        const rect = target.getBoundingClientRect();
        const player = this.$refs.stemPlayerPanel;
        player.style.top = `${target.offsetTop}px`;
        player.style.left = `${rect.left}px`;
        player.style.width = `${rect.width}px`;
        player.style['margin-top'] = '0px';
        target.style.height = `${player.clientHeight}px`;
      });
    },
    addListeners() {
      const el = this.$refs.stemPlayerPanel;
      const resizeObserver = new ResizeObserver(() => this.render());
      resizeObserver.observe(el);
      window.addEventListener('resize', this.render, true);
    },
    removeListeners() {
      window.removeEventListener('resize', this.render, true);
    },
    onStemPlayerLoad(track) {
      this.track = track;
    },
    view(player) {
      this.$router.push({
        name: player.isAdmin ? 'adminViewTrack' : 'publicViewTrack',
        params: {
          track_id: player.track_id,
        },
      });
    },
  },
};
</script>

<style scoped></style>
