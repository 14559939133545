<template>
  <modal v-slot="scope">
    <form autocomplete="off" @submit.prevent="submit($event, scope)">
      <div class="min-vh-75 d-flex align-items-center py-1 text-center">
        <div class="w-100">
          <div class="bg-dark-4 list-group py-3 mb-4">
            <div
              class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            >
              <label class="form-label w-50 mb-0" for="ttle">Title</label>
              <input
                type="text"
                name="title"
                id="title"
                class="form-control form-control-lg"
                required="true"
                maxlength="25"
                placeholder="Song Title"
                v-model="player.label"
                ref="label"
              />
            </div>
          </div>
          <div class="text-end">
            <button class="btn btn-lg btn-primary" type="submit">OK</button>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'EditStemPlayerControlsModal',
  components: { Modal },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
    },
  },
  computed: {
    player() {
      return this.modelValue;
    },
  },
  data() {},
  mounted() {
    this.$refs.label?.focus();
  },
  methods: {
    submit(e, scope) {
      e.preventDefault();
      this.$emit('update:modelValue', this.modelValue);
      scope.close();
    },
  },
};
</script>

<style scoped></style>
