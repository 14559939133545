import { API } from 'aws-amplify';

/**
 * Get ALL tracks for the current user
 *
 * @param {Object} options
 * @returns
 */
const adminGetTracks = async (options) => {
  const params = [
    options.limit ? `limit=${options.limit}` : undefined,
    options.modifiedSince ? `modifiedSince=${options.modifiedSince}` : '',
    options.force ? `t=${Date.now()}` : '', // cache buster
  ].filter((e) => !!e);

  const result = await API.get('admin', `/admin/tracks/?${params.join('&')}`);

  // for the moment, we simply paginate, since the user has a limit of max tracks anyway
  // if it becomes possible that a user has thousands of tracks in total, then we need to
  // look into pregenerating a dump and loading that first
  // const { LastEvaluatedKey } = result.data;

  // // if there is another page,.. get it
  // if (options.limit === -1 && LastEvaluatedKey) {
  //   const response = { Items: [...result.data.Items] };

  //   const nextPage = await adminGetTracks({
  //     ...options,
  //     LastEvaluatedKey,
  //   });

  //   response.Items.push(...nextPage.Items);

  //   return response;
  // }

  return result.items;
};

export default adminGetTracks;
