<template>
  <modal v-slot="scope">
    <download-modal
      v-if="modal === 'download'"
      @close="scope.close()"
      :allowDownloadMix="allowDownloadMix"
      :track="track"
    ></download-modal>
    <div class="min-vh-75 d-flex align-items-center py-1 text-center">
      <div class="w-100">
        <div class="bg-dark-4 list-group py-3">
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
            @click="view(scope)"
          >
            View
          </button>
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
            @click="mix(scope)"
          >
            Play
          </button>
          <!-- <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
            @click="mix(scope)"
          >
            Mix
          </button> -->
          <!-- <button
              class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
              @click="playNext(scope)"
            >
              Play Next
            </button>
            <button
              class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
              @click="addToQueue(scope)"
            >
              Add to Queue
            </button> -->
          <!-- <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
            @click="details(scope)"
          >
            Details
          </button> -->

          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
            @click="edit(scope)"
          >
            Edit
          </button>
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item text-center"
            @click="download(scope)"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';
import DownloadModal from './DownloadModal.vue';

export default {
  name: 'TrackOptionsModal',
  components: { Modal, DownloadModal },
  props: {
    track: {
      type: Object,
      required: true,
    },
    allowDownloadMix: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  mounted() {},
  methods: {
    // playInline(scope) {
    //   this.$player.playMix(this.track, {
    //     autoplay: true,
    //     isAdmin: this.isAdmin,
    //   });

    //   scope.close();
    // },
    // play(scope) {
    //   if (this.isAdmin) this.adminPlaytrack();
    //   else this.playTrack();
    //   scope.close();
    // },
    view(scope) {
      this.$router.push({
        name: this.isAdmin ? 'adminViewTrack' : 'publicViewTrack',
        params: {
          track_id: this.track.track_id,
        },
        query: {
          t: this.track.modifiedTimestamp,
        },
      });

      scope.close();
    },
    mix(scope) {
      this.$router.push({
        name: this.isAdmin ? 'adminMixTrack' : 'publicMixTrack',
        params: {
          track_id: this.track.track_id,
        },
        query: {
          t: this.track.modifiedTimestamp,
        },
      });

      scope.close();
    },
    playNext(scope) {
      scope.close();
    },
    addToQueue(scope) {
      scope.close();
    },
    download() {
      this.modal = 'download';
    },
    share() {
      this.modal = 'share';
    },
    embed() {
      this.modal = 'embed';
    },
    edit(scope) {
      scope.close();
      this.$router.push({
        name: 'adminEditTrack',
        params: {
          track_id: this.track.track_id,
        },
        query: {
          t: this.track.modifiedTimestamp,
        },
      });
    },
  },
};
</script>

<style scoped></style>
