<template>
  <modal v-slot="scope">
    <div class="min-vh-almost-100 d-flex align-items-center py-1">
      <div class="w-100">
        <section
          class="card bg-dark-4 mb-4 text-light"
          v-if="!track.isPublished && !isAdmin"
        >
          <div class="card-body">
            <p class="alert alert-warning text-center" role="alert">
              <i class="bi bi-exclamation-triangle-fill"></i> Embedding a
              private track can only be done from the private resource page.
            </p>
          </div>
        </section>

        <div v-else>
          <!-- <section class="card bg-dark-4 mb-4 text-light">
            <div class="card-header text-secondary mb-0 text-end border-0">
              <h5 class="mb-0 scroll-m-top-4" ref="general">Embed</h5>
            </div>
            <div class="card-body">
              <div class="form-group mb-3">
                <label class="required form-label" for="trackFormSongTitle"
                  >Valid until</label
                >
                <input
                  name="songTitle"
                  type="date"
                  class="form-control"
                  id="trackFormSongTitle"
                />
              </div>
            </div>
          </section> -->

          <section class="card bg-dark-4 mb-4 text-light">
            <div class="card-header text-secondary mb-0 text-end border-0">
              <h5 class="mb-0 scroll-m-top-4" ref="general">Embed options</h5>
            </div>
            <div class="card-body">
              <fieldset>
                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkboxAllowHighQualityMix"
                    :disabled="
                      track.isPublished || !$acl.can('acl:track:downloadMix')
                    "
                    v-model="allowDownloadMix"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxAllowHighQualityMix"
                    >Allow Download of High Quality Mix
                  </label>
                  <tooltip class="ms-2" v-if="track.isPublished"
                    >Downloading of a lossles mix for publicly available tracks
                    is not permitted</tooltip
                  >
                  <tooltip
                    class="ms-2"
                    v-if="!$acl.can('acl:track:downloadMix')"
                    >Your account does not give you access to this
                    feature</tooltip
                  >
                </div>
                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkboxAllowHighQualityMix"
                    v-model="collapsible"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxAllowHighQualityMix"
                    >Toggle Stems
                  </label>
                </div>
                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkboxAllowHighQualityMix"
                    v-model="collapsed"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxAllowHighQualityMix"
                    >Collapsed
                  </label>
                </div>
              </fieldset>
            </div>
          </section>

          <section class="card bg-dark-4 mb-4 text-light">
            <div class="card-header text-secondary mb-0 text-end border-0">
              <h5 class="mb-0 scroll-m-top-4" ref="general">Preview</h5>
            </div>
            <div class="card-body preview d-flex align-items-center">
              <div class="w-100">
                <Loader v-if="isLoading"></Loader>
                <EmbedPreview
                  v-else
                  :collapsed="collapsed"
                  :streamUrl="publicGetTrackUrl"
                  :controls="controls"
                  :maxHeight="`300px`"
                ></EmbedPreview>
              </div>
            </div>
          </section>

          <section class="card bg-dark-4 mb-4 text-light">
            <div class="card-header text-secondary mb-0 text-end border-0">
              <h5>Embed Code</h5>
            </div>

            <div class="card-body">
              <div class="form-group mb-2">
                <copy-to-clipboard
                  :disabled="isLoading"
                  class="mb-2"
                  :rows="8"
                  :value="embedCodeWebComponent"
                ></copy-to-clipboard>
                <p class="text-center small mb-0">
                  <i class="bi bi-info-circle-fill"></i> Copy the code above and
                  paste it into your webpage in order to embed your player
                </p>
                <!-- <p
                  id="descriptionHelp"
                  class="form-text text-light text-center"
                >
                  The embed code expires in 24 hours.
                  <router-link
                    target="_blank"
                    :to="{ name: 'docs' }"
                    class="text-muted"
                    >See docs for info on embedding private content</router-link
                  >
                </p> -->
              </div>
            </div>
          </section>

          <section class="card bg-dark-4 mb-4 text-light">
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-10"></div>
                <div class="col-sm-2 text-end">
                  <button
                    class="btn btn-dark"
                    type="submit"
                    @click="scope.close()"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
/* eslint-disable no-useless-escape */
import CopyToClipboard from './CopyToClipboard.vue';
import Modal from './Modal.vue';
import EmbedPreview from './EmbedPreview.vue';
import Tooltip from './Tooltip.vue';
import Loader from './Loader.vue';
import config from '../../config';
import adminCreateTrackAccessToken from '../services/adminCreateTrackAccessToken';

export default {
  name: 'EmbedModal',
  components: { CopyToClipboard, Modal, EmbedPreview, Loader, Tooltip },
  props: {
    track: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    embedUrl() {
      return `${window.location.origin.replace(
        /\/$/,
        '',
      )}/mixthat-embed.js?track_id=${this.track.track_id}${
        this.token ? `?authToken=${this.token}` : ``
      }`;
    },
    apiUrl() {
      return `${window.location.origin}`;
    },
    controls() {
      return [
        this.collapsible ? 'toggle:stems' : '',
        this.allowDownloadMix ? 'download:mix' : '',
      ]
        .filter((x) => !!x)
        .join(' ');
    },
    embedCodeWebComponent() {
      return `<!-- paste the below just before the </head> tag -->
<script type="module" src="${window.location.origin}/js/bundled/mixthat-player/dist/module.js"><\/script>
<style>
:root {
  --stemplayer-js-controls-background-color: #232323;
}
</style>
<!-- Paste the below in the HTML document where you would like the player to appear -->
<mixthat-player controls="${this.controls}" src="${this.publicGetTrackUrl}"></mixthat-player>`;
    },
    publicGetTrackUrl() {
      const apiEndpoint = config.amplify.API.endpoints.find(
        (endpoint) => endpoint.name === 'cdn',
      ).endpoint;

      if (this.track.isPublished) {
        return `${apiEndpoint}/tracks/${this.track.track_id}/stream`;
      } else {
        if (this.token) {
          return `${apiEndpoint}/tracks/${this.track.track_id}/stream?authToken=${this.token}`;
        }
      }

      return undefined;
    },
  },
  data() {
    return {
      token: undefined,
      isLoading: false,
      allowDownloadMix: false,
      collapsible: false,
      collapsed: false,
    };
  },
  async beforeMount() {
    if (!this.track.isPublished) {
      return this.loadTrackAccessToken();
    }
  },
  methods: {
    async loadTrackAccessToken() {
      // if not published... get an access token
      this.isLoading = true;
      try {
        this.token = undefined;
        this.token = await adminCreateTrackAccessToken(this.track.track_id, {
          'acl:track:downloadMix': this.allowDownloadMix,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    allowDownloadMix() {
      this.loadTrackAccessToken();
    },
  },
};
</script>
<style scoped>
.preview {
  min-height: 375px;
}
</style>
