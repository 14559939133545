<template>
  <track-options-modal
    v-if="showOptions && track"
    :track="track"
    :isAdmin="isAdmin"
    @close="showOptions = false"
  ></track-options-modal>

  <div class="mb-2 text-end z-index-1000 position-relative">
    <!--<button
      class="btn btn-light btn-sm rounded-circle me-2"
      type="submit"
      @click="showLarge = true"
    >
      <i class="bi bi-arrows-fullscreen"></i>
    </button>-->
    <button
      class="btn btn-primary btn rounded-circle"
      type="submit"
      @click="showOptions = true"
    >
      <i class="bi bi-three-dots-vertical"></i>
    </button>
  </div>
  <div class="card bg-dark-4">
    <div class="card-body px-0 py-3">
      <div class="min-vh-player d-flex align-items-center" v-if="isLoading">
        <div class="w-100 p-4">
          <loader v-if="!hideLoader"></loader>
        </div>
      </div>
      <div class="min-vh-player d-flex align-items-center" v-if="isNotReady">
        <div class="w-100 p-4 text-center text-light">
          <div role="alert">
            <p class="w-75 mx-auto mb-2">
              The audio could not be streamed. If you have recently created this
              track, then this is likely because the audio is still being
              prepared. Stem separation will normally take a few minutes.
            </p>
            <button class="btn btn-light" id="selectFiles" @click="loadTrack()">
              <i class="bi bi-arrow-clockwise"></i>
              Retry
            </button>
          </div>
        </div>
      </div>
      <div class="min-vh-player d-flex align-items-center" v-if="isError">
        <div class="w-100 p-4 text-center text-light">
          <div role="alert">
            <i class="bi bi-exclamation-triangle-fill"></i> The streaming audio
            could not be loaded
          </div>
        </div>
      </div>

      <stemplayer-js
        v-if="track"
        ref="player"
        :autoplay="autoplay"
        :sample-rate="acSamplerate"
        :id="playerId"
        :data-track_id="track.track_id"
        :audioContext="audioCtx"
        regions
      >
        <stemplayer-js-controls
          :label="track.label"
          slot="header"
        ></stemplayer-js-controls>
        <stemplayer-js-stem
          v-for="(file, index) in track.files.filter(
            (file) => file.type === 'STEM',
          )"
          :id="file.file_id"
          :key="index"
          :label="file.label"
          :src="`https://uat.mixthat.co${file.$links[0].href}`"
          :waveform="`https://uat.mixthat.co${file.$links[1].href}`"
        >
        </stemplayer-js-stem>
      </stemplayer-js>
    </div>
  </div>
</template>

<script>
import { v4 as createUuid } from 'uuid';
import { cdnEndpoint, audioPresets } from '../../config';
// import { recordPlayerEvents } from "../services/analytics";
// import streamTrack from '../services/streamTrack';
// import adminStreamTrack from '../services/adminStreamTrack';
import Loader from './Loader.vue';
import { record } from '../services/analytics';
import { PLAY_MIX, PLAY_MIX_FAIL } from '../consts/analytics';
import TrackOptionsModal from './TrackOptionsModal.vue';
import createAudioContext from '@/libs/createAudioContext';
import adminGetTrack from '@/services/adminGetTrack';
import getTrack from '@/services/getTrack';

export default {
  name: 'StemPlayer',
  components: { Loader, TrackOptionsModal },
  props: {
    track_id: {
      type: String,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    hideLoader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load'],
  data() {
    return {
      acSamplerate: audioPresets.acSampleRate,
      isNotReady: false,
      cdnEndpoint,
      playerId: createUuid(),
      track: undefined,
      streamUrls: undefined,
      isLoading: false,
      isError: false,
      showOptions: false,
    };
  },
  computed: {
    player() {
      return this.$refs.player;
    },
    audioCtx() {
      return createAudioContext();
    },
  },
  async beforeMount() {
    try {
      await this.loadTrack();

      if (!this.isNotReady) {
        record(PLAY_MIX, {
          track_id: this.track_id,
          isAdmin: this.isAdmin ? true : undefined,
        });
      }
    } catch (error) {
      this.$notify(
        {
          title: 'Error',
          text: 'We encountered a problem when playing the track',
          type: 'info',
          group: 'default',
        },
        4000,
      );

      record(PLAY_MIX_FAIL, {
        track_id: this.track_id,
        isAdmin: this.isAdmin ? true : undefined,
      });
    }
  },
  methods: {
    async loadTrack() {
      try {
        this.isLoading = true;
        this.isNotReady = false;

        const provider = this.isAdmin ? adminGetTrack : getTrack;

        // // get the track with streaming urls
        this.track = await provider(this.track_id, {
          authToken: this.authToken,
          cache: false,
        });

        this.$emit('load', this.track);

        setTimeout(() => {
          // view naviages to play mix if the current viewed track is playing. This causes $refs.player to be destroyed
          if (this.$refs.player) {
            this.$refs.player.addEventListener('error', (err) => {
              console.error(err);
            });
          }
        }, 0);
      } catch (err) {
        if (err.response?.status === 503) {
          this.isNotReady = true;
        } else {
          this.isError = true;

          throw err;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
