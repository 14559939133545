<template>
  <EditStemPlayerControlsModal
    v-if="showEditPlayerModal"
    v-model="player"
    @close="showEditPlayerModal = false"
    @update:modelValue="updatePlayerProps"
  >
  </EditStemPlayerControlsModal>
  <EditStemModal
    v-if="selectedStem"
    v-model="stemToEdit"
    @close="selectedStem = undefined"
    @update:modelValue="(e) => updateStemProps(e, selectedStem)"
  ></EditStemModal>
  <form autocomplete="off" @submit.prevent="submit($event, scope)">
    <div class="mx-4">
      <file-drop-panel
        class="min-vh-filedrop"
        @drop="stageFiles"
        :maxFiles="maxUploads"
        :showButton="false"
        v-slot="scope"
      >
        <div class="container">
          <div class="mb-2 text-end z-index-1000 position-relative">
            <button
              class="btn btn-primary btn rounded-circle"
              type="button"
              @click="showOptions = true"
            >
              <i class="bi bi-gear-fill"></i>
            </button>
          </div>
          <div class="card bg-dark-4 mb-4">
            <div class="card-body px-0 py-3">
              <stemplayer-js ref="player" :audioContext="audioCtx">
                <stemplayer-js-controls
                  :label="label"
                  slot="header"
                  @click="showEditPlayerModal = true"
                ></stemplayer-js-controls>
                <stemplayer-js-stem
                  v-for="(stem, index) in stems"
                  @click="selectedStem = stem"
                  :key="index"
                  :label="stem.label"
                  :src="stem.src"
                  :waveform="stem.waveform"
                ></stemplayer-js-stem>
              </stemplayer-js>
            </div>
          </div>
          <div class="text-end">
            <button
              class="btn btn-lg btn-secondary me-2"
              type="button"
              @click="scope.open"
            >
              Add Files
            </button>
            <button
              class="btn btn-lg btn-primary"
              type="submit"
              :disabled="!stems.length"
            >
              Save
            </button>
          </div>
        </div>
      </file-drop-panel>
    </div>
  </form>
</template>

<script>
import { ref, inject } from 'vue';
import FileDropPanel from './FileDropPanel.vue';
import createAudioContext from '@/libs/createAudioContext';
import EditStemModal from './EditStemModal.vue';
import EditStemPlayerControlsModal from './EditStemPlayerControlsModal.vue';

export default {
  name: 'UploadStems',
  components: { FileDropPanel, EditStemModal, EditStemPlayerControlsModal },
  setup() {
    const createTrackManager = ref(inject('createTrackManager'));

    return {
      createTrackManager,
    };
  },
  props: {
    title: {
      type: String,
    },
    maxUploads: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      label: 'New Track',
      showEditPlayerModal: false,
      selectedStem: undefined,
    };
  },
  computed: {
    player() {
      return {
        label: this.label,
      };
    },
    stemToEdit() {
      return this.selectedStem ? { ...this.selectedStem } : undefined;
    },
    stems() {
      return this.createTrackManager.items;
    },
    audioCtx() {
      return createAudioContext();
    },
  },
  methods: {
    stageFiles({ acceptFiles, rejectReasons }) {
      if (rejectReasons) {
        const errors = [].concat.apply(
          [],
          rejectReasons.map(({ errors }) => errors),
        );

        if (errors.find((error) => error.code === 'too-many-files')) {
          this.showTooManyUploadsNotification();
          return;
        }
      }

      // if (this.files.length + acceptFiles.length > this.maxUploads) {
      //   this.showTooManyUploadsNotification();
      //   return;
      // }

      this.createTrackManager.push(...acceptFiles);
    },
    showTooManyUploadsNotification() {
      this.$notify(
        {
          title: 'Error',
          text: `Too many stems selected add ${this.maxUploads} stems`,
          type: 'error',
          group: 'default',
        },
        4000,
      );
    },
    edit(element) {
      console.log(element.target.tagName);
    },
    updatePlayerProps(e) {
      this.label = e.label;
    },
    updateStemProps(e, stem) {
      stem.label = e.label;
    },
    submit(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped>
.min-vh-filedrop {
  min-height: calc(100vh - 125px);
}
stemplayer-js-controls:hover,
stemplayer-js-stem:hover {
  background-color: rgb(126, 97, 0);
}
</style>
