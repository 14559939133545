const { Auth } = require('aws-amplify');

// We restrict the number of concurrent uploads as this may lead to timeouts
// also Amplify already splits up a large file and uploads the parts concurrently
module.exports.maxConcurrentUploads = 4;

module.exports.appEndpoint = process.env.VUE_APP_ENDPOINT;

module.exports.cdnEndpoint = process.env.VUE_APP_API_ENDPOINT;

module.exports.analytics = {
  streamName: process.env.VUE_APP_ANALYTICS_STREAM_NAME,
};

module.exports.administratorEmail = 'hello@sound.ws';

module.exports.maxUploadFileSize = 100 * 1024 * 1024; // 100 meg

module.exports.maxLocalTracksSearch = 100000;

module.exports.mixthatPlayerPath = '/js/mixthat-player.js';

module.exports.localDb = {
  prefetchPageSize: 250,
};

module.exports.audioPresets = {
  acSampleRate: 44100,
  ogg: {
    format: 'oga',
    bitRate: 96,
    sampleRate: 48000,
    segmentTime: 5,
  },
  mp3: {
    format: 'mp3',
    bitRate: 128,
    sampleRate: 44100,
    segmentTime: 5,
  },
};

// https://serverless-stack.com/chapters/configure-aws-amplify.html
// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#top-level-configuration-3
module.exports.amplify = {
  Auth: {
    mandatorySignIn: false,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_APP_CLIENT_ID,
  },
  oauth: {
    domain: process.env.VUE_APP_OAUTH_DOMAIN,
    scope: ['openid', 'email', 'tracks/admin'],
    redirectSignIn: `${process.env.VUE_APP_ENDPOINT}/oauth-callback`,
    redirectSignOut: `${process.env.VUE_APP_ENDPOINT}/`,
    // clientId: '5ic6u9l3csd89kr4q61am1q7od', //7fcjop41bjkai5m3egdbpjveb5
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    // socialProviders: ['google'],
  },
  Storage: {
    region: process.env.VUE_APP_AWS_REGION,
    bucket: process.env.VUE_APP_UPLOAD_BUCKET,
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    level: 'private',
  },
  API: {
    endpoints: [
      {
        name: 'admin',
        endpoint: `${process.env.VUE_APP_API_ENDPOINT.replace(/\/$/, '')}`,
        region: process.env.VUE_APP_AWS_REGION,

        // This is to ensure we can protect the lambda with a cognito authorizer instead of an iam authoriser (which wont work when proxying requests). See README.md
        custom_header: async () => ({
          Authorization: (await Auth.currentSession()).accessToken.jwtToken,
        }),
      },

      {
        name: 'cdn',
        endpoint: `${process.env.VUE_APP_API_ENDPOINT.replace(/\/$/, '')}`,
        region: process.env.VUE_APP_AWS_REGION,

        // This is to ensure we can protect the lambda with a cognito authorizer instead of an iam authoriser (which wont work when proxying requests). See README.md
        // suppresses requests to cognito which just add latency,
        // also we dont want the CDN to take the authorization headeer in the cache key which will just increase cache-misses
        custom_header: async () => ({
          Authorization: 'mock',
        }),
      },
    ],
  },
  AWSKinesisFirehose: {
    // OPTIONAL -  Amazon Kinesis Firehose service region
    region: process.env.VUE_APP_AWS_REGION,

    // OPTIONAL - The buffer size for events in number of items.
    bufferSize: 1000,

    // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushSize: 100,

    // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 5000, // 5s

    // OPTIONAL - The limit for failed recording retries.
    resendLimit: 5,
  },
};
