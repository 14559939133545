<template>
  <div id="stem-player-target" class="text-center overflow-hidden">
    <div class="d-flex align-items-center min-vh-player p-4 rounded-3"></div>
  </div>
</template>

<script>
export default {
  name: 'StemPlayerTarget',
  data() {
    return {
      track: undefined,
    };
  },
  props: {
    track_id: {
      type: String,
      required: true,
    },
    authToken: {
      type: String,
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    forceReleoad: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    const { track_id, authToken } = this;

    // dont play same track
    if (this.$player.track_id !== track_id) {
      this.$player.playMix(
        { track_id },
        {
          autoplay: this.autoplay,
          force: this.forceReleoad,
          isAdmin: this.isAdmin,
          authToken,
        },
      );
    }
  },
  watch: {
    $route() {
      setTimeout(() => {
        // Notify the stemplayerpanel wrapper that the target div is mounted so that the player position can be re-calculated
        this.emitter.emit('STEMPLAYER_TARGET_MOUNTED');
      }, 10);
    },
  },
  mounted() {
    setTimeout(() => {
      // Notify the stemplayerpanel wrapper that the target div is mounted so that the player position can be re-calculated
      this.emitter.emit('STEMPLAYER_TARGET_MOUNTED');
    }, 10);
  },
};
</script>

<style scoped>
.min-vh-player {
  min-height: 25em;
}
</style>
