<template>
  <div class="min-vh-almost-100 d-flex align-items-center">
    <div class="w-100">
      <div class="container">
        <auth initial-state="signUp">
          <div
            class="alert bg-dark-4 text-light text-center w-50 m-auto min-vh-25 d-flex align-items-center"
          >
            <div class="w-100">
              <p>No need to sign up, you are are already logged in.</p>
              <p class="m-0">
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'uploadStems' }"
                  >Try uploading some music!</router-link
                >
              </p>
            </div>
          </div>
          <p></p>
        </auth>
      </div>
    </div>
  </div>
</template>

<script>
import { Hub } from 'aws-amplify';
import Auth from './Auth.vue';

export default {
  name: 'Signup',
  components: { Auth },
  mounted() {
    const onSignin = () => {
      this.$router.push({ name: 'adminListTracks' });
      Hub.remove('auth', onSignin);
    };

    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') onSignin();
    });
  },
};
</script>

<style scoped></style>
