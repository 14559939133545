import { reactive } from 'vue';
import uploadFile from '@/services/uploadFile';
import createUid from '@/libs/uid';
import getS3Url from './getS3Url';
import getHLSUrl from './getHLSUrl';
import getWaveformUrl from './getWaveformUrl';

const doUpload = (file) => {
  const uid = createUid();

  const upload = {
    uid,
    key: `${uid}.${file.name.split('.').pop()}`,
    uploadProgress: 0,
  };

  const handle = uploadFile(upload.key, file, {
    level: 'private',
    contentType: file.type,
    progressCallback: (v) => {
      upload.uploadProgress = Math.round((v.loaded / v.total) * 100);
    },
  });

  return {
    ...upload,
    ...handle,
  };
};

class CreateTrackManager {
  constructor() {
    this.items = [];
  }

  push(...files) {
    files.forEach((file) => {
      const item = reactive({
        label: file.name,
        upload: doUpload(file),
      });

      item.upload.promise.then(() => {
        getS3Url(item.upload.key).then((r) => {
          item.src = getHLSUrl(r, item.upload.uid);
          item.waveform = getWaveformUrl(r, item.upload.uid);
        });
      });

      this.items.push(item);
    });
  }
}

export default CreateTrackManager;
