<template>
  <div>
    <stemplayer-js regions>
      <stemplayer-js-controls
        label="Funk Demo"
        slot="header"
        style="position: sticky; top: 0; z-index: 99"
      ></stemplayer-js-controls>
      <stemplayer-js-stem
        label="Guitar A"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Guitar_1.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Guitar_1.json"
        volume="0.1"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Guitar B"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Guitar_2.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Guitar_2.json"
        volume="0.2"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Organ"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Organ_1.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Organ_1.json"
        volume="0.3"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Bass"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Bass_1.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Bass_1.json"
        volume="0.4"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Cowbell"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Cowbell_1.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Cowbell_1.json"
        volume="0.5"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Casaba"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Casaba_1.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Casaba_1.json"
        volume="0.6"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Congas"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Congas_1.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Congas_1.json"
        volume="0.7"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Drums A"
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Drums_1.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Drums_1.json"
        volume="0.8"
      >
      </stemplayer-js-stem>
      <stemplayer-js-stem
        label="Drums B  "
        src="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/m3u8/Stem_Drums_2.m3u8"
        waveform="https://d2m8h53em6mi65.cloudfront.net/default/drumsv2/json/Stem_Drums_2.json"
        volume="0.9"
      >
      </stemplayer-js-stem>
    </stemplayer-js>
  </div>
</template>

<script>
export default {
  name: 'StemPlayerFunkDemo',
};
</script>

<style scoped></style>
