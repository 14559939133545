<template>
  <modal v-slot="scope">
    <div
      v-if="isLoading"
      class="min-vh-100 bg-waves d-flex align-items-center py-1"
    >
      <div class="w-100 margin-top-min-navbar">
        <loader></loader>
      </div>
    </div>
    <div v-else class="min-vh-75 d-flex align-items-center py-1 text-center">
      <div class="w-100">
        <section class="card bg-dark-4 mb-4 text-light">
          <div class="card-header text-secondary mb-0 text-end border-0">
            <h5>Share</h5>
          </div>
          <div class="card-body" v-if="showPrivateShareWarning">
            <p class="alert alert-warning text-center" role="alert">
              <i class="bi bi-info-circle-fill"></i> Sharing a private track can
              only be done from the private resource page.
            </p>
          </div>
          <div class="card-body" v-else>
            <form>
              <div
                id="descriptionHelp"
                class="form-text text-muted text-end"
                v-if="!track.isPublished"
              >
                The link expires in 24 hours
              </div>
              <copy-to-clipboard
                :value="playMixUrl"
                :rows="5"
              ></copy-to-clipboard>
            </form>
          </div>

          <div class="card-footer text-end">
            <button class="btn btn-dark" type="submit" @click="scope.close()">
              Close
            </button>
          </div>
        </section>
      </div>
    </div>
  </modal>
</template>

<script>
import CopyToClipboard from './CopyToClipboard.vue';
import Modal from './Modal.vue';
import Loader from './Loader.vue';
import adminCreateTrackAccessToken from '../services/adminCreateTrackAccessToken';

export default {
  name: 'ShareModal',
  components: { CopyToClipboard, Modal, Loader },
  props: {
    track: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playMixUrl: undefined,
      isLoading: false,
      showPrivateShareWarning: false,
    };
  },
  async beforeMount() {
    if (!this.isAdmin && !this.track.isPublished) {
      this.showPrivateShareWarning = true;
    }

    this.isLoading = true;

    try {
      const resolved = this.$router.resolve({
        name: 'publicMixTrack',
        params: {
          track_id: this.track.track_id,
        },
        query: {
          authToken:
            // when we're sharing a private track, include an access token
            this.isAdmin && !this.track.isPublished
              ? await adminCreateTrackAccessToken(this.track.track_id, {
                  expires: '24h',
                })
              : undefined,
        },
      });

      this.playMixUrl = `${window.location.origin}${resolved.href}`;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-twitter {
  background-color: #55acee;
}
.bg-facebook {
  background-color: #3b5998;
}
.bg-linkedin {
  background-color: #007bb5;
}
.bg-whatsapp {
  background-color: #25d366;
}
.bg-fb-messenger {
  background-color: #00b2ff;
}
</style>
