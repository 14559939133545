<template>
  <div class="text-center overflow-hidden position-relative">
    <stem-player
      :track_id="track_id"
      :autoplay="false"
      :isAdmin="isAdmin"
      :authToken="authToken"
      @load="stemAudioIsAvailable = true"
    ></stem-player>

    <div
      v-if="stemAudioIsAvailable"
      class="position-absolute top-0 left-0 w-100 h-100 z-index-999 d-flex align-items-center"
    >
      <div class="w-100">
        <router-link
          class="btn btn-dark bg-transparent border-0 text-light text-glow-light text-glow-light-lg-hover"
          :to="{
            name: this.isAdmin ? 'adminMixTrack' : 'publicMixTrack',
            params: {
              track_id: this.track_id,
            },
            query: {
              authToken: this.authToken,
            },
          }"
        >
          <i class="bi bi-play-circle-fill display-1"></i
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import StemPlayer from '../StemPlayer';

export default {
  name: 'ViewTrackView',
  components: { StemPlayer },
  data() {
    return { stemAudioIsAvailable: undefined };
  },
  props: {
    track_id: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    authToken: {
      type: String,
      required: false,
    },
  },
  async beforeMount() {
    // if the current track is playing.. redirect to playMix
    if (this.track_id === this.$player.track_id) {
      this.$router.replace({
        name: this.isAdmin ? 'adminMixTrack' : 'publicMixTrack',
        params: { track_id: this.track_id },
      });
    }
  },
};
</script>

<style scoped>
.text-glow-light {
  text-shadow: 0px 0px 5px #ccc;
  color: #ddd !important;
  transition:
    color 500ms ease,
    text-shadow 500ms ease;
}
.text-glow-light-lg-hover:hover {
  color: #fff !important;
  text-shadow: 0px 0px 10px white;
}
</style>
