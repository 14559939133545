<template>
  <div>
    <auth>
      <account>
        <div>
          <router-view></router-view>
        </div>
      </account>
    </auth>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import Auth from '../Auth.vue';
import Account from '../Account.vue';

export default {
  name: 'AdminListTracks',
  components: { Auth, Account },
  setup() {
    const createTrackManager = ref(inject('createTrackManager'));

    return {
      createTrackManager,
    };
  },
};
</script>

<style scoped></style>
