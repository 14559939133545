import { reactive } from 'vue';

class PlayerController {
  track = undefined;
  autoplay = undefined;

  async playMix({ track_id }, { autoplay, isAdmin, authToken }) {
    this.track_id = undefined;
    this.autoplay = autoplay;
    this.isAdmin = isAdmin;
    this.authToken = authToken;

    setTimeout(() => {
      this.track_id = track_id;
    }, 10);
  }

  unset() {
    this.track_id = undefined;
    this.autoplay = undefined;
    this.isAdmin = undefined;
    this.authToken = undefined;
  }
}

export default {
  install(app) {
    app.config.globalProperties.$player = reactive(new PlayerController());
    app.provide('player', app.config.globalProperties.$player);
  },
};
