<template>
  <stem-player-target
    :track_id="track_id"
    :autoplay="autoplay"
    :isAdmin="isAdmin"
  ></stem-player-target>
</template>

<script>
import StemPlayerTarget from '../StemPlayerTarget.vue';

export default {
  name: 'ViewTrackPlay',
  components: { StemPlayerTarget },
  props: {
    track_id: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
