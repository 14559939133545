<template>
  <div
    v-bind="getRootProps()"
    class="align-items-center border-4-dashed-muted d-flex text-center position-relative"
  >
    <div class="w-100" @click.stop>
      <div
        class="m-auto px-3 py-3 position-absolute bottom-0 w-100 text-end border-top-dark-gray z-index-top text-center"
      >
        <div>
          <span v-if="title" class="me-2 text-muted">{{ title }}</span>
          <button
            v-if="showButton"
            @click.stop="open"
            class="btn btn-secondary mx-3"
          >
            Select Files
          </button>
        </div>
        <input v-bind="getInputProps()" />
      </div>

      <slot @click.stop :open="open"></slot>
    </div>
  </div>
</template>

<script>
import config from '../../config';
import { useDropzone } from 'vue3-dropzone';

export default {
  name: 'FileDropPanel',
  props: {
    title: {
      type: String,
      default: 'Drop your Files',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: Array,
      default() {
        return ['.mp3', '.wav', '.aiff', '.flac'];
      },
    },
    maxUploadFileSize: {
      type: Number,
      default: config.maxUploadFileSize,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
  },
  emits: ['drop'],
  setup(props, context) {
    const onDrop = (acceptFiles, rejectReasons) => {
      context.emit('drop', { acceptFiles, rejectReasons });
    };

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      multiple: true,
      accept: props.accept,
      maxSize: props.maxUploadFileSize,
      maxFiles: props.maxFiles,
    });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  methods: {
    open2(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped></style>
